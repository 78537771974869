import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../config/keys";

const initialState = {
  data: {
    ordersData: null,
    status: null,
    totalRestaurant: null,
    usersData: null,
    weeklyAvgOrderAmount: null,
    weeklyOrderProfit: null,
    networkError: null,
  },
};

export const Orders = createAsyncThunk("orders", async (city) => {
  try {
    const result = await axios.post(`${baseUrl}/web/v1/dashboard`, {
      city: city,
    });
    if (result.data.status == "200") {
      return result.data;
    } else {
      return initialState.data;
    }
  } catch (error) {
    return error;
  }
});
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload.data;
      
    },
    removeData: (state, action) => {
      state.data =action.payload.data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(Orders.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
  // [Orders.fulfilled]:(state,action)=>{
  //   state.data=action.payload
  //   }
});

export const { setData,removeData } = dashboardSlice.actions;
export const selectData = (state) => state.dashboardReducer.data;
export default dashboardSlice.reducer;
