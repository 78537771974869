import React from "react";
import styles from "./AssignModal.module.css";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import { useState } from "react";
import { ClipLoader } from 'react-spinners'
const AssignModal = ({ closeModal, assignRider, city }) => {
    // console.log("🚀 ~ file: AssignModal.js:42 ~ AssignModal ~ city", city)

    const [riders, setRiders] = useState([])
    const [selectedRider, setSelectedRider] = useState()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        (async () => {
            try {
                const result = await axios.post(`${baseUrl}/admin/activeRiders`, {
                    city: city
                })
                if (result.data.status == '200') {
                    // console.log('xz', result.data)
                    setRiders(result.data.activeRiders)
                }
                setLoading(false)
            } catch (e) {
                console.log(e)
            }

        })()
    }, [city])
    const findRider = (riderId) => {
        const rider = riders?.filter((item) => item._id == riderId)
        setSelectedRider(rider[0])
    }
    return <div className={styles.assign}>
        <div className={styles.header}>
            <h5>
                Select Rider
            </h5>
            <IconButton
                onClick={closeModal}
                className={styles.icon}
            >
                <CloseIcon />
            </IconButton>
        </div>
        <div className={styles.riderContainer}>
            {
                riders?.map((item) => (
                    <div className={styles.riderDetail}
                        key={item.id}
                    >
                        <div className={styles.riderItem}>
                            <input type="radio"
                                name="options"
                                value={item._id}
                                onChange={(t) => findRider(t.target.value)}
                            />
                            <h5>{item.name}</h5>
                            <span style={{ color: "red" }}>({item.activeOrders})</span>
                        </div>

                        <span
                            style={{ color: item.status == "idle" ? "mediumseagreen" : "red" }}
                        >{item.status}</span>
                    </div>
                ))
            }
        </div>
        {riders.length > 0 && !loading ? <div className={styles.bottomContainer}>
            <h5>Are you sure you want to assign?</h5>
            <button
                disabled={!selectedRider}
                onClick={() => assignRider(selectedRider)}
            >Confirm</button>
        </div>
            : riders.length < 1 && !loading ? <div className={styles.noRider}>
                <h5>
                    No Rider Available
                </h5></div> : null}
        {
            loading &&
            <div className={styles.loading}>
                <ClipLoader
                    color="#ff7378"
                    size={50}
                />
            </div>

        }
    </div >;
};

export default AssignModal;
