import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: {
    product: [],
  },
};

export const basketSlice = createSlice({
  name: "basket",
  initialState: initialState,
  reducers: {
    addToBasket: (state, action) => {
      state.items.product = action.payload.product;
    },
    addProductToCart: (state, action) => {
      console.log("🚀 ~ file: basketSlice.js:132 ~ action:", action.payload);
      const newProduct = [...state.items.product];
      const checkProduct = newProduct.some(
        (item) =>
          item.productName === action.payload.productName &&
          item.quantity === action.payload.quantity &&
          (item.selectedFlavours
            ? item.selectedFlavours.every((flavoursItem) =>
                action.payload.selectedFlavours.some(
                  (payloadItems) =>
                    flavoursItem.option === payloadItems.option &&
                    flavoursItem.title === payloadItems.title
                )
              )
            : true)
      );
      if (checkProduct) {
        var result = newProduct.map((item) => {
          return item.productName === action.payload.productName &&
            item.quantity === action.payload.quantity &&
            (item.selectedFlavours
              ? item.selectedFlavours.every((flavoursItem) =>
                  action.payload.selectedFlavours.some(
                    (payloadItem) =>
                      flavoursItem.option === payloadItem.option &&
                      flavoursItem.title === payloadItem.title
                  )
                )
              : true)
            ? {
                ...item,
                count: item.count + action.payload.count,
                net: item.net + action.payload.net,
                // selectedFlavours: action.payload.selectedFlavours,
              }
            : item;
        });
        state.items.product = result;
      } else {
        state.items.product = [...state.items.product, action.payload];
      }
    },

    // addProductIntoCartForFlavour: (state, action) => {
    //   console.log(
    //     "addProductIntoCartForFlavour action.payload 42",
    //     action.payload
    //   );
    //   const newProductForFlavour = [...state.items.product];

    //   const result = newProductForFlavour.filter(
    //     (item) =>
    //       item.productName === action.payload.productName &&
    //       item.quantity === action.payload.quantity
    //   );
    //   console.log("addProductIntoCartForFlavour: result 50", result);
    //   const getResult = result.filter((newItem) =>
    //     newItem.selectedFlavours.every((getItem) =>
    //       action.payload.selectedFlavours.some(
    //         (item) =>
    //           item.option === getItem.option && getItem.title === item.title
    //       )
    //     )
    //   );
    //   console.log("addProductIntoCartForFlavour: getResult 51", getResult);
    //   if (getResult.length > 0) {
    //     const index = result.findIndex((newItem) =>
    //       newItem.selectedFlavours.every((getItem) =>
    //         action.payload.selectedFlavours.some(
    //           (item) =>
    //             item.option === getItem.option && getItem.title === item.title
    //         )
    //       )
    //     );
    //     console.log("addProductIntoCartForFlavour: index 68", index);
    //     const mapped = getResult.map((item) => {
    //       return {
    //         ...item,
    //         count: item.count + action.payload.count,
    //         net: item.net + action.payload.net,
    //       };
    //     });
    //     console.log("addProductIntoCartForFlavour: 77 mapped", mapped);
    //     newProductForFlavour.splice(index, 1, mapped[0]);

    //     state.items.product = newProductForFlavour;
    //   } else {
    //     state.items.product = [...state.items.product, action.payload];
    //   }
    // },

    removeProductFromBasket: (state, action) => {
      console.log("🚀 ~ file: basketSlice.js:140 ~ action:", action.payload);
      const newProduct = [...state.items.product];
      const removedItem = newProduct.map((item) => {
        return item.productName === action.payload.productName &&
          item.quantity === action.payload.quantity &&
          (item.selectedFlavours
            ? item.selectedFlavours.every((flavoursItem) =>
                action.payload.selectedFlavours.some(
                  (payloadItem) =>
                    flavoursItem.option === payloadItem.option &&
                    flavoursItem.title === payloadItem.title
                )
              )
            : true)
          ? {
              ...item,
              count: item.count - 1,
              net: (item.count - 1) * item.price,
            }
          : item;
      });
      state.items.product = removedItem;
    },
    completeRemoveProductFromBasket: (state, action) => {
      const index = state.items.product.findIndex((item) => {
        return (
          item.productName === action.payload.productName &&
          item.quantity === action.payload.quantity &&
          (item.selectedFlavours
            ? item.selectedFlavours.every((flavoursItem) =>
                action.payload.selectedFlavours.some(
                  (payloadItem) =>
                    flavoursItem.option === payloadItem.option &&
                    flavoursItem.title === payloadItem.title
                )
              )
            : true)
        );
      });
      const newBasket = [...state.items.product];
      newBasket.splice(index, 1);
      state.items.product = newBasket;
    },
    clearTheBasket: (state, action) => {
      state.items.product = [];
    },
  },
});

export const {
  addToBasket,
  addProductToCart,
  removeProductFromBasket,
  completeRemoveProductFromBasket,
  clearTheBasket,
  addProductIntoCartForFlavour,
} = basketSlice.actions;
export const selectedBasketItem = (state) => state.basketReducer.items;
export default basketSlice.reducer;
