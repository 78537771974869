import React, { useEffect, useState } from "react";
import styles from "./RestaurantModal.module.css";
import { Close, LocalMall, Search, Whatshot, Add } from '@mui/icons-material';
import { Badge, IconButton } from "@mui/material";
import AddToCart from "../addToCart/AddToCart";
import Cart from "../cart/Cart";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct } from "../../features/productSlice";
import { addProductToCart, selectedBasketItem } from "../../features/basketSlice";


const RestaurantModal = ({ restaurantModalClose, restaurantData }) => {
console.log("🚀 ~ file: RestaurantModal.js:13 ~ RestaurantModal ~ restaurantData:", restaurantData)

  const { products } = useSelector(selectProduct)
  const basketItems = useSelector(selectedBasketItem)


  const [addToCart, setAddToCart] = useState(false)
  const [product, setProduct] = useState()
  const [productAddedToCart, setProductAddedToCart] = useState([])
  const [cart, setCart] = useState(false)
  const [cartCount, setCartCount] = useState(0)
  const [borderName, setBorderName] = useState('')
  const [charges, setCharges] = useState()
  const addProduct = (item) => {
    setProduct(item)
    setAddToCart(true)
  }
  const isScroll = (refName) => {
    var element = document.getElementById(refName)
    element.scrollIntoView()
  }

  return <div
    className={styles.restaurantModal}
  >
    <div className={styles.headerContainer}>
      <h5>
        {restaurantData.martName}
      </h5>
      <div
        className={styles.iconContainer}

      >
        <IconButton
          onClick={() => setCart(true)}
        >
          <Badge
            badgeContent={basketItems?.product?.length} color="primary">
            <LocalMall

              className={styles.cartIcon}
            />
          </Badge>
        </IconButton>

        <IconButton
          onClick={restaurantModalClose}>
          <Close
            className={styles.close}
          />
        </IconButton></div>


    </div>
    <div className={styles.searchContainer}>
      <div className={styles.search}>
        <Search
          className={styles.searchIcon}
        />
        <input type="text" placeholder="Search" className={styles.inputBox} />
      </div>

    </div>
    <div className={styles.categoryList}>
      {products?.data?.map((item) => (
        <button
          style={{ borderBottom: borderName == item.category ? '2px solid #ff7378' : 'white' }}
          onClick={() => [setBorderName(item.category), isScroll(item.category)]}
        >
          <h5

          >
            {item.category}
          </h5>
        </button>

      ))}
    </div>
    <div className={styles.categoryContainer}>
      {products?.data?.map((product) => (
        <div
          id={product.category}
        >
          <div className={styles.title}>
            <Whatshot
              className={styles.shotIcon}
            />
            <h5>
              {product.category}
            </h5>
          </div>

          <div className={styles.productItemContainer}>

            {product?.data?.map((item) => (
              <div className={styles.product}
                onClick={() => addProduct(item)}
              >
                <div className={styles.contentContainer}>
                  <h5
                  >{item.productName}
                  </h5>
                  <h5
                    style={{ fontSize: "13px", color: "rgba(0,0,0,0.8)" }}
                  >
                    {item.quantity}
                  </h5>
                  <div style={{ display: "flex", textAlign: 'center' }}>
                    <h5>from</h5>
                    <h5
                      style={{
                        textDecorationLine: item.discount == "0" ? 'none' : 'line-through',
                        textAlign: 'center',
                        marginLeft:"5px"

                      }}
                    >
                      Rs.{item.price}
                    </h5>
                    {
                      item.discount >'0'&&
                      <h5 style={{marginLeft:"20px",color:'#ff7378'}}>Rs.{item.discountedPrice}</h5>
                    }
                  </div>

                </div>
                <IconButton
                  className={styles.plusIcon}>
                  <Add
                  />
                </IconButton>

              </div>
            ))}
          </div>

        </div>
      ))}
    </div>
    {addToCart &&
      <div className={styles.modalContainer}>
        <AddToCart
          modalClose={() => [setAddToCart(false), setProduct('')]}
          product={product}
        />
      </div>
    }
    {
      cart &&
      <div className={styles.modalContainer}
      >
        <Cart
          cartClose={() => setCart(false)}
          product={productAddedToCart}
          charges={charges}

        />
      </div>
    }
  </div>;
};

export default RestaurantModal;
