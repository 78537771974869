import React from "react";
import styles from "./CancelModal.module.css";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
const reasons = [
  {
    id: 1,
    title: "Customer cancelled"
  },
  {
    id: 2,
    title: "Rider not available"
  },
  {
    id: 3,
    title: "Out of range"
  },
  {
    id: 4,
    title: "Exceed delivery time"
  }
]
const CancelModal = ({ modalClose, setReason, cancelOrder ,reason}) => {

  return <div className={styles.cancelModal}>
    <div className={styles.header}>
      <h5>Cancellation Reason</h5>
      <IconButton
        className={styles.closeIcon}
      >
        <CloseIcon
          onClick={modalClose}
        >
        </CloseIcon>
      </IconButton>

    </div>
    <div className={styles.reasonContainer}>
      {
        reasons.map((item) => (
          <div className={styles.selectReason} key={item.id}>
            <h5>{item.title}</h5>
            <input type="radio"
              name="options"
              value={item.title}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        ))
      }
      <div className={styles.otherReason}>
        <h5>Other</h5>
        <textarea
          onChange={(e) => setReason(e.target.value)}
          name="" id="" cols="15" rows="2"
          placeholder="Other Reason"
        ></textarea>
      </div>
    </div>
    <div className={styles.buttonContainer}>
      <button
      disabled={!reason}
        onClick={() => cancelOrder()}
      >Confirm</button>
    </div>
  </div>;
};

export default CancelModal;
