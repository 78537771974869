import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./features/dashboardSlice";
import userData from "./features/userSlice";
import basketReducer from "./features/basketSlice";
import productsReducer from "./features/productSlice";
import orderReducer from "./features/orderSlice";
import refreshReducer from "./features/refreshSlice";
export const store = configureStore({
  reducer: {
    dashboardReducer,
    userData,
    basketReducer,
    productsReducer,
    orderReducer,
    refreshReducer,
  },
});
