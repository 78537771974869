import React, { useEffect } from "react";
import styles from "./RecordCard.module.css";
import { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const RecordCard = () => {
  const navigate = useNavigate();
  const selectedCity = JSON.parse(localStorage.getItem("currentCity"));
  const userData = JSON.parse(localStorage.getItem("userLogin"));
  console.log("🚀 ~ file: RecordCard.js:24 ~ RecordCard ~ userData:", userData);
  const [start, setStartDate] = useState();
  const [end, setEndDate] = useState();
  const [filter, setFilter] = useState(false);
  const [item, setItem] = useState();
  const [city, setCity] = useState(selectedCity.city);
  const [loading, setLoading] = useState(false);
  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    // console.log(ranges);
  };
  const selectionRange = {
    startDate: start,
    endDate: end,
    key: "selection",
  };
  const startDate = moment(start).format("DD-MM-YYYY");
  const endDate = moment(end).format("DD-MM-YYYY");
  const getResult = async () => {
    setFilter(false);
    const res = await axios.post(`${baseUrl}/admin/expensesTillNow`, {
      startDate,
      endDate,
      city,
    });
    setLoading(false);
    setItem({
      avgOrderAmount: res.data.avgOrderAmount,
      paidToRestaurants: res.data.paidToRestaurants,
      paidToRiders: res.data.paidToRiders,
      totalOrders: res.data.totalOrders,
      totalProfit: res.data.totalProfit,
      totalSale: res.data.totalSale,
    });
    // console.log(res.data);
  };
  const load = [1, 2, 3, 4, 5, 6];

  return (
    <div className={styles.expenseCard__container}>
      <div className={styles.expenseCard__header}>
        <FormControl sx={{ width: "10%" }} size="small">
          <InputLabel id="simple-select-label">City</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simpleSelect"
            label="Age"
            sx={{ height: "45px" }}
            onChange={(v) => setCity(v.target.value)}
            defaultValue={selectedCity.city}
          >
            {userData?.adminCities?.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          
          </Select>
        </FormControl>
        <div className={styles.searchBox}>
          <Button disabled={!city} onClick={() => setFilter(true)}>
            Filter By Dates
          </Button>
          <div className={styles.dateBox}>
            <h5>
              {startDate}-{endDate}
            </h5>
          </div>
          {filter && (
            <div className={styles.dateRange}>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                className={styles.dateRangePicker}
              />
              <Button
                className={styles.searchButton}
                onClick={() => [getResult(), setLoading(true)]}
              >
                Search Dates
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={styles.expenseCard__data}>
        <div className={styles.expenseCard__dataTitlesBar}>
          <h5>Total Orders</h5>
          <h5>Avg Order Amount</h5>
          <h5>Total Sales</h5>
          <h5>Restaurants Payments</h5>
          <h5>Rider Salary</h5>
          <h5>Total Profit</h5>
        </div>
        <div className={styles.expenseCard__dataItem}>
          {item && !loading ? (
            <>
              <h6>{item?.totalOrders?.toLocaleString()}</h6>
              <h6>{item?.avgOrderAmount?.toFixed()}</h6>
              <h6>{item?.totalSale?.toLocaleString()}</h6>
              <h6>{item?.paidToRestaurants?.toLocaleString()}</h6>
              <h6>{item?.paidToRiders?.toLocaleString()}</h6>
              <h6>{item?.totalProfit?.toLocaleString()}</h6>
            </>
          ) : loading ? (
            <>
              {load.map(() => (
                <Skeleton />
              ))}
            </>
          ) : (
            <div className={styles.noRecord}>
              <h5>Search Record To Show</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecordCard;
