import React, { useEffect } from "react";
import SideBar from "../../components/sidebar/SideBar";
import styles from "./OrderStyle.module.css";
import { useNavigate, useParams } from "react-router-dom";
import OrdersCard from "../../components/weeklyOrdersCard/OrdersCard";

const Orders = ({ docTitle }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userLogin"));
  // console.log("🚀 ~ file: Orders.js:11 ~ Orders ~ userData", userData);
  useEffect(() => {
    if (userData) {
      document.title = docTitle;
    } else {
      navigate("/login");
    }
  }, []);
  if (!userData) {
    return null;
  }
  return (
    <div className={styles.order}>
      <div className={styles.order__sideBar}>
        <SideBar />
      </div>
      <div className={styles.order__container}>
        <OrdersCard
          title={id === "1" ? "Weekly" : id === "2" ? "Monthly" : "Yearly"}
          id={id}
        />
      </div>
    </div>
  );
};

export default Orders;
