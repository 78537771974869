import React, { useEffect, useState } from "react";
import styles from "./TrackingCard.module.css";
import {
  NavigateNext,
  NavigateBefore,
  Upcoming,
  SwipeRight,
  CheckBox,
  Cancel,
  Refresh,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import SelectBox from "../selectBox/SelectBox";
import OrderDetailsModal from "../orderDetailsModal/OrderDetailsModal";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { addOrder } from "../../features/orderSlice";
import { selectRefresh } from "../../features/refreshSlice";
import OneSignal from "react-onesignal";
const city = [
  { name: "Sargodha", label: "city" },
  { name: "Chiniot", label: "city" },
];

const TrackingCard = () => {
  const [border, setBorder] = useState({
    upcoming: true,
    accepted: false,
    picked: false,
    rejected: false,
  });
  const selectedCity = JSON.parse(localStorage.getItem("currentCity"));

  const [data, setData] = useState();
  const [dataToMap, setDataToMap] = useState([]);
  const [cityItem, setCityItem] = useState(selectedCity.city);
  const [zone, setZone] = useState(
    selectedCity.city === "Sargodha" ? "Sargodha 1" : "Chiniot 1"
  );
  const [zones, setZones] = useState();
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginateRecord, setPaginateRecord] = useState();
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [loading, setLoading] = useState(true);
  const [notifiedStatus, setNotifiedStatus] = useState("Notify to Customer");
  const dispatch = useDispatch();
  const refreshThePage = useSelector(selectRefresh);
  const userData = JSON.parse(localStorage.getItem("userLogin"));
   useEffect(()=>{
       OneSignal.on('notificationDisplay',function(isNotificationDisplay){
            if(isNotificationDisplay){
              console.log("🚀 ~ file: TrackingCard.js:58 ~ OneSignal.on ~ isNotificationDisplay:🎉", isNotificationDisplay)   
              setRefresh(!refresh)
            }
       })
   },[])
  useEffect(() => {
    console.log('Here after notification')
    if (cityItem && zone) {
      (async () => {
        const result = await axios.post(`${baseUrl}/admin/allOrders`, {
          city: cityItem,
          adminType: "",
          zone: zone,
        });
        console.log("🚀 ~ file: TrackingCard.js:46 ~ result🎉", result.data);
        if (result.data.status == "200") {
          setData(result.data);
          const { upcoming, accepted, picked, rejected } = border;
          if (upcoming) {
            setDataToMap(result.data.upcoming);
          }
          if (accepted) {
            setDataToMap(result.data.accepted);
          }
          if (picked) {
            setDataToMap(result.data.picked);
          }
          if (rejected) {
            setDataToMap(result.data.rejected);
          }
          setLoading(false);
        }
      })();
    }
  }, [cityItem, zone, refresh]);

  //>>>>>>>>>>>>>>>>>>>>> Pagination <<<<<<<<<<<<<<<<<<<//
  useEffect(() => {
    if (dataToMap) {
      const totalPages = Math.ceil(dataToMap?.length / recordPerPage);
      setTotalPages(totalPages);
      const indexOfLastRecord = currentPage * recordPerPage;
      const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
      const record = dataToMap?.slice(indexOfFirstRecord, indexOfLastRecord);
      setPaginateRecord(record);
    }
  }, [dataToMap, currentPage]);

  const pageNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const pageBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  //>>>>>>>>>>>>>>>>>>>>> Pagination <<<<<<<<<<<<<<<<<<<//
  const getZones = async (e) => {
    setCityItem(e);
    const result = await axios.post(`${baseUrl}/web/v1/getCityZones`, {
      city: e,
    });
    setZones(result.data.zones.zones);
  };
  useEffect(() => {
    getZones(selectedCity?.city);
  }, []);

  const orderAccept = async ({ data, customerNotified, status }) => {
    // console.log(
    //   "🚀 ~ file: TrackingCard.js:118 ~ orderAccept ~ status:",
    //   status
    // );
    setLoading(true);
    setModal(false);
    try {
      const result = await axios.post(`${baseUrl}/orders/restaurantResponse`, {
        orderId: data._id,
        status: status,
        customerNotified: !customerNotified
          ? data.customerNotified
          : customerNotified,
        orderLatitude: data.latitude,
        orderLongitude: data.longitude,
        orderNum: data.orderNum,
        orderType: data.orderType,
        martId: data.martId,
      });
      // console.log("notify", result.data);
      if (result.data.status == "200") {
        setRefresh(!refresh);
        alert(result.data.msg);
      } else {
        setRefresh(!refresh);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const orderRejected = async ({ data, reason }) => {
    setModal(false);
    setLoading(true);
    try {
      const result = await axios.post(`${baseUrl}/orders/restaurantResponse`, {
        orderId: data._id,
        status: "Rejected",
        customerNotified: data.customerNotified,
        orderLatitude: data.latitude,
        orderLongitude: data.longitude,
        reason: reason,
      });
      // console.log("rejected", result);
      setRefresh(!refresh);
    } catch (e) {
      console.log(e);
    }
  };

  const orderPickedUp = async (data) => {
    setLoading(true);
    try {
      const result = await axios.post(`${baseUrl}/orders/changeOrderStatus`, {
        orderId: data._id,
        status: "Rider Picked Up",
      });
      setModal(false);
      setRefresh(!refresh);
    } catch (e) {
      console.log(e);
    }
  };

  const orderDelivered = async (data) => {
    setModal(false);
    setLoading(true);
    try {
      const result = await axios.post(`${baseUrl}/orders/changeOrderStatus`, {
        orderId: data._id,
        status: "Delivered",
      });
      setModal(false);
      setRefresh(!refresh);
    } catch (e) {
      console.log(e);
    }
  };
  const undoOrder = async (data) => {
    setModal(false);
    setLoading(true);
    try {
      const result = await axios.post(`${baseUrl}/admin/reopenOrder`, {
        orderId: data._id,
        actions: {
          name: "Web",
        },
      });

      if (result.data.status == "200") {
        setModal(false);
        setRefresh(!refresh);
        setLoading(true);
      } else {
        setRefresh(!refresh);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    pageRefresh();
    updatePage();
  }, [refreshThePage]);
  //>>>>>>>>>>>>>>>>>>>Update The page<<<<<<<<<<<<<<<<//
  const pageRefresh = () => {
    setLoading(true);
    setModal(false);
  };
  const updatePage = () => {
    setRefresh(!refresh);
  };

  //>>>>>>>>>>>>>>>>>>>Update The page<<<<<<<<<<<<<<<<<<//

  return (
    <div className={styles.trackingCard}>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderStatus}>
          <button
            onClick={() => [
              setBorder({
                accepted: false,
                picked: false,
                delivered: false,
                upcoming: true,
              }),
              setDataToMap(data?.upcoming),
              setCurrentPage(1),
            ]}
            style={{
              borderBottom: border?.upcoming && "2px solid #ff7378",
              borderRadius: border?.upcoming && "0",
            }}
            className={styles.upcoming}
          >
            <Upcoming className={border.upcoming ? styles.borderColor : ""} />
            <h2 className={border.upcoming ? styles.borderColor : ""}>
              Upcoming
            </h2>
          </button>
          <button
            disabled={!data}
            onClick={() => [
              setBorder({
                accepted: true,
                picked: false,
                delivered: false,
                upcoming: false,
              }),
              setDataToMap(data?.accepted),
              setCurrentPage(1),
            ]}
            style={{
              borderBottom: border.accepted && "2px solid #ff7378",
              borderRadius: border?.accepted && "0",
            }}
            className={styles.upcoming}
          >
            <SwipeRight
              className={border?.accepted ? styles.borderColor : ""}
            />
            <h2 className={border?.accepted ? styles.borderColor : ""}>
              Accepted
            </h2>
          </button>
          <button
            disabled={!data}
            className={styles.upcoming}
            onClick={() => [
              setBorder({
                accepted: false,
                picked: true,
                delivered: false,
                upcoming: false,
              }),
              setDataToMap(data?.picked),
              setCurrentPage(1),
            ]}
            style={{
              borderBottom: border.picked && "2px solid #ff7378",
              borderRadius: border?.picked && "0",
            }}
          >
            <CheckBox className={border.picked ? styles.borderColor : ""} />
            <h2 className={border.picked ? styles.borderColor : ""}>Picked</h2>
          </button>
          <button
            disabled={!data}
            className={styles.upcoming}
            onClick={() => [
              setBorder({
                accepted: false,
                picked: false,
                rejected: true,
                upcoming: false,
              }),
              setDataToMap(data?.rejected),
              setCurrentPage(1),
            ]}
            style={{
              borderBottom: border?.rejected && "2px solid #ff7378",
              borderRadius: border?.rejected && "0",
            }}
          >
            <Cancel className={border.rejected ? styles.borderColor : ""} />
            <h2 className={border.rejected ? styles.borderColor : ""}>
              Rejected
            </h2>
          </button>
          <div className={styles.count}>
            <h3>{data ? data?.totalOrders : "0"}</h3>
          </div>
          <div onClick={() => setRefresh(!refresh)} className={styles.refresh}>
            <IconButton onClick={() => setLoading(true)}>
              <Refresh className={styles.refreshIcon} />
            </IconButton>
          </div>
        </div>
        <div className={styles.select}>
          <SelectBox
            data={userData.adminCities}
            label="City"
            selectItem={(e) => getZones(e)}
            defaultValue={selectedCity.city}
          />
          <SelectBox
            disable={!zones ? true : false}
            data={zones}
            label="Zones"
            selectItem={(e) => setZone(e)}
            defaultValue={zone}
          />
        </div>
      </div>
      <div className={styles.subHeader}>
        <h2>Order No</h2>
        <h2>Date&Time</h2>
        <h2>Total Price</h2>
        <h2>Status</h2>
        <h2>Order Type</h2>
        <h2>Payment</h2>
        <h2>Actions</h2>
      </div>
      <div className={styles.itemsDataContainer}>
        {loading && (
          <div className={styles.loading}>
            <ClipLoader color="#ff7378" size={50} />
          </div>
        )}
        {paginateRecord?.length > 0 && !loading ? (
          paginateRecord?.map((item) => (
            <div key={item._id} className={styles.item}>
              <span style={{ marginLeft: "10px" }}>{item.orderNum}</span>
              <span style={{ marginLeft: "10px" }}>
                {item.date}
                <br />
                {item.time}
              </span>
              <span style={{ textAlign: "center" }}>
                {item?.orderTotal?.toLocaleString()}
              </span>
              <span style={{ textAlign: "right", color: "mediumseagreen" }}>
                {item.status}
              </span>
              <span style={{ textAlign: "right" }}>{item.orderType}</span>
              <span style={{ textAlign: "right" }}>{item.paymentType}</span>
              <span
                onClick={() => [
                  setModal(true),
                  setOrderData(item),
                  dispatch(addOrder(item)),
                ]}
                style={{ textAlign: "right", color: "red", padding: "10px" }}
              >
                View Details
              </span>
            </div>
          ))
        ) : paginateRecord?.length <= 0 && !loading ? (
          <div className={styles.showTitle}>
            <h5>Nothing To Show</h5>
          </div>
        ) : null}
      </div>
      <div className={styles.pagination}>
        <div className={styles.paginationBox}>
          <button
            disabled={paginateRecord?.length < 1 ? true : false}
            onClick={() => pageBack()}
            className={styles.back}
          >
            <NavigateBefore />
          </button>
          <div className={styles.status}>
            <span>{currentPage}</span>
          </div>
          <button
            disabled={paginateRecord?.length < 1 ? true : false}
            onClick={() => pageNext()}
            className={styles.next}
          >
            <NavigateNext />
          </button>
        </div>
      </div>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.newModal} />
          <OrderDetailsModal
            modalClose={() => setModal(false)}
            data={orderData}
            status={border}
            orderRejected={orderRejected}
            orderAccept={orderAccept}
            orderPickedUp={orderPickedUp}
            orderDelivered={orderDelivered}
            orderUndo={undoOrder}
            refresh={() => pageRefresh()}
            updatePage={() => updatePage()}
            city={cityItem}
            notifiedStatus={notifiedStatus}
          />
        </div>
      )}
    </div>
  );
};

export default TrackingCard;
