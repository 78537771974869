import React from 'react'
import CustomersCard from '../../components/customersCard/CustomersCard'
import SideBar from '../../components/sidebar/SideBar'
import styles from './Customers.module.css'
function Customers() {
    return (
        <div className={styles.customer}>
            <div className={styles.sideBar}>
                <SideBar />
            </div>
            <div className={styles.customerCard}>
                <CustomersCard />
            </div>

        </div>
    )
}

export default Customers