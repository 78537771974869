import React, { useState } from "react";
import styles from "./SelectCity.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeData } from "../../features/dashboardSlice";
const SelectCity = () => {
    const [selectedCity, setSelectedCity] = useState()
    const userData = JSON.parse(localStorage.getItem('userLogin'))
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selectCity = () => {
        localStorage.setItem('currentCity', JSON.stringify({
            city: selectedCity
        }))
        dispatch(removeData(
            {
                data: {
                    ordersData: null,
                    status: null,
                    totalRestaurant: null,
                    usersData: null,
                    weeklyAvgOrderAmount: null,
                    weeklyOrderProfit: null,
                    networkError: null,
                },
            }
        ))
        navigate('/')
    }
    return <div className={styles.selectCity}>
        <div className={styles.selectCity_modal}>
            <div className={styles.selectCity_leftBox}>
                <h5>
                    Select a city
                </h5>
                <Button
                    onClick={selectCity}
                    disabled={!selectedCity}
                    sx={{
                        '&:hover': {
                            backgroundColor: '#000',
                            color: 'white'
                        }
                    }}>
                    Continue
                </Button>
            </div>
            <div className={styles.citiesList}>
                {userData?.adminCities?.map((item) => (
                    <div
                        key={item.toString()}
                        className={styles.button_box}
                        style={{
                            borderBottom: '1px solid lightgrey'
                        }}
                    >
                        <Button
                            onClick={() => setSelectedCity(item)}
                        >
                            <span>
                                {item}
                            </span>
                        </Button>
                    </div>
                ))}

            </div>
        </div>
    </div>;
};

export default SelectCity;
