import React, { useState } from "react";
import styles from "./EditProfileModal.module.css";
import { Close, NearMeDisabledTwoTone } from '@mui/icons-material';
import { Button, IconButton, TextField } from "@mui/material";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
function EditProfileModal({ editModalClose, fieldsData, searchTheUser }) {

  const [name, setName] = useState(fieldsData.name)
  const [email, setEmail] = useState(fieldsData.email)
  const [phone, setPhone] = useState(fieldsData.phone)
  const [password, setPassword] = useState(fieldsData.password)
  const updateData = async (e) => {
    editModalClose()
    e.preventDefault()
    try {
      const result = await axios.post(`${baseUrl}/user/editProfile`, {
        userId: fieldsData._id,
        name,
        email,
        password,
        phone
      })
      if (result.data.status == '200') {
        searchTheUser()

        alert('Profile Updated')
      } else {
        alert(result.data.msg)
      }

    }
    catch (e) { console.log(e) }

  }
  return <div className={styles.editProfile}>
    <div className={styles.header}>
      <h5>Edit Profile</h5>
      <IconButton
        onClick={editModalClose}
      >
        <Close />
      </IconButton>

    </div>
    <div className={styles.editContainer}>
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ marginTop: '10px' }}
        id="outlined-basic" label="Name" variant="outlined" />
      <TextField
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        style={{ marginTop: '10px' }}

        id="outlined-basic" label="Phone" variant="outlined" />
      <TextField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ marginTop: '10px' }}
        id="outlined-basic" label="Email" variant="outlined" />
      <TextField
        value={password}

        onChange={(e) => setPassword(e.target.value)}
        style={{ marginTop: '10px' }}
        id="outlined-basic" label="Password" variant="outlined" />
    </div>
    <div style={{display:'flex',justifyContent:'center'}}>
      <Button
        onClick={updateData}
        style={{ backgroundColor: '#ff7478', width: '35%', padding: '10px 0px', marginTop: '40px' }}
        variant="contained">Update</Button>
    </div>




  </div>;
}

export default EditProfileModal;
