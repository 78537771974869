import React from "react";
import styles from "./FlavourDetailModal.module.css";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
const FlavourDetailModal = ({ flavourModalClose, flavours }) => {
   
    return <div
        className={styles.flavourDetailModal}
    >
        <div className={styles.header}>
            <h5>Flavours</h5>
            <IconButton
                onClick={flavourModalClose}
            >
                <CloseIcon />
            </IconButton>
        </div>
        <div className={styles.contentContainer}>
            {
                flavours?.map((item) => (
                    <div className={styles.flavourItem}>
                        <ul>
                            <li>
                                <h5>{item.title}</h5>
                                <span>{item.option}</span>
                            </li>

                        </ul>

                    </div>
                ))
            }
        </div>
    </div>;
};

export default FlavourDetailModal;
