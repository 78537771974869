import React, { useEffect, useState } from "react";
import styles from "./Flavour.module.css";
const Flavour = ({ specification, findFlavour }) => {
// console.log("🚀 ~ file: Flavour.js:4 ~ Flavour ~ specification:", specification)


  return (
   <div className={styles.flavour}>
      <div className={styles.flavourContainer}>
        {specification?.map((flavour) => (
          <div>
            <div className={styles.header}>
              <h5>{flavour.title}</h5>
              <div className={styles.required}>
                <span>1 Required</span>
              </div>
            </div>
            {flavour?.data?.map((item) => (
              <div className={styles.itemContainer}>
                <div className={styles.item}>
                  <input type="radio" name={flavour.title} value={item.txt}
                    onChange={(t) => findFlavour({ option: t.target.value, title: flavour.title })}
                  />
                  <h6>{item.txt}</h6>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>


    </div>
  );
};

export default Flavour;
