import React from "react";
import { BarLoader } from "react-spinners";
import logo from "../../assets/dastak.png";
const Loading = () => {
  return (
    <>
      <img width={70} src={logo} style={{ marginBottom: "15px" }} />
      <BarLoader
        color="#ff7378"
        loading={true}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default Loading;
