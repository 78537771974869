import { Search } from '@mui/icons-material'
import React, { useState } from 'react'
import styles from './CustomersCard.module.css';
import axios from 'axios';
import { REACT_APP_BASE_URL as baseUrl } from '../../config/keys';
import { IconButton } from '@mui/material';
import EditProfileModal from '../editProfileModal/EditProfileModal';
import { ClipLoader } from 'react-spinners'
function CustomersCard() {

  const [searchInput, setSearchInput] = useState()
  const [usersData, setUsersData] = useState([])
  const [status, setStatus] = useState('Search The Customer')
  const [editModal, setEditModal] = useState(false)
  const [fieldsData, setFieldsData] = useState()
  const [loading, setLoading] = useState(false)


  const handleSubmit = async (e) => {

    e.preventDefault()
    searchTheUser()
  }
  const searchTheUser = async () => {
    setLoading(true)
    if (searchInput?.trim()) {
      try {
        const result = await axios.post(`${baseUrl}/admin/searchUser`, {
          credentials: searchInput
        })
        if (result.data.status == '200') {
          console.log(result.data)
          setUsersData(result.data.users)
          setLoading(false)
          if (result?.data?.users?.length == 0) {
            setStatus('No Such Customer Found')
          }
        }

      } catch (e) {
        console.log(e)
      }
    } else {
      setLoading(false)
      alert('Enter Credentials To Search')
    }
  }
  return (
    <div
      className={styles.customersCard}>
      <div className={styles.header}>
        <h5>Customers</h5>
      </div>
      <div className={styles.search}>
        <form className={styles.input}>
          <IconButton
            onClick={handleSubmit}
          >
            <Search className={styles.searchIcon} />
          </IconButton>

          <input

            onChange={(e) => setSearchInput(e.target.value)}
            type="text" placeholder='Search' className={styles.inputBox} />
          <button
            onClick={handleSubmit}
            className={styles.searchButton} type='submit' ></button>

        </form>
      </div>
      <div className={styles.titleBar}>
        <h2>Customer name</h2>
        <h2>Phone</h2>
        <h2>Email</h2>
        <h2>Password</h2>
        <h2>Type</h2>
        <h2>Edit Details</h2>
      </div>
      <div className={styles.dataContainer}>
        {usersData.length > 0 ? (usersData?.map((item) => (
          <div className={styles.dataItem}>
            <span>
              {item.name}
            </span>
            <span style={{ textAlign: 'right', width: '120px' }}>{item.phone}</span>
            <span style={{ textAlign: 'center', width: '170px', fontSize: "13px", wordBreak: 'break-all', position: 'relative', left: "0px" }} >{item.email ? item.email : 'none'}</span>

            <span

              style={{ color: "mediumseagreen", textAlign: 'center', wordBreak: 'break-all', fontSize: "13px" }}>{item.password}</span>
            <span style={{ textAlign: 'center' }} >{item.type}</span>
            <span
              onClick={() => [setFieldsData(item), setEditModal(true)]}
              style={{ textAlign: 'center', color: 'red', padding: '10px 1px' }}>Edit</span>
          </div>
        ))
        ) : usersData.length <= 0 && !loading ?
          <div className={styles.searchTitle}>
            <span>{status}</span>
          </div>
          : null}
      </div>
      {editModal &&
        <div className={styles.editModal}>
          <EditProfileModal
            editModalClose={() => setEditModal(false)}
            fieldsData={fieldsData}
            searchTheUser={searchTheUser}
          />
        </div>
      }
      {
        loading &&
        <div className={styles.loading}>
          <ClipLoader
            size={50}
            color='#ff7378' />
        </div>
      }
    </div>
  )
}

export default CustomersCard