import React from "react";
import logo from "../../assets/dastak.png";
import { AiOutlineSearch } from "react-icons/ai";
import { Avatar, Badge } from "@mui/material";
import { ChatBubbleOutlineOutlined, ExpandMore, MarkunreadOutlined } from "@mui/icons-material";
import styles from "./Header.module.css";
import avatarLogo from "../../assets/avatarLogo.jpg";


const Header = ({ clickOnImage }) => {
  const { name } = JSON.parse(localStorage.getItem('userLogin'))
  return (
    <div className={styles.header}>
      <img
        src={logo}
        alt={"dastak-logo"}
        className={styles.header__icon}
        width={55}
        onClick={clickOnImage}
      />
      <div className={styles.header__right}>
        <div className={styles.header__search}>
          <input type="text" placeholder="Search Here..." />
          <AiOutlineSearch className={styles.icon} size={25} />
        </div>

        <Badge badgeContent={0} color="primary" className={styles.badge}>
          <MarkunreadOutlined sx={{ color: "#434c56 ", padding: "0 5px" }} />
        </Badge>
        <ChatBubbleOutlineOutlined
          className={styles.badge}
          sx={{ color: "#000 ", padding: "0 10px 0 5px" }}
        />
        <div className={styles.avatar}>
          <Avatar src={avatarLogo} />

          <ExpandMore />

        </div>
        <span
          className={styles.adminName}>
          {name && name}
        </span>

      </div>
    </div>
  );
};

export default Header;
