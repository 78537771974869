import React, { useEffect, useState } from "react";
import styles from "./OrderDetailsModal.module.css";
import {
  Close,
  ExpandLess,
  ExpandMore,
  Person,
  LocalPhone,
  FmdGood,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CancelModal from "../cancelModal/CancelModal";
import AssignModal from "../assignModal/AssignModal";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import RemoveRider from "../removeRider/RemoveRider";
import RestaurantModal from "../restaurantModal/RestaurantModal";
import { useDispatch } from "react-redux";
import { addToBasket } from "../../features/basketSlice";
import { clearProducts, productThunk } from "../../features/productSlice";
import FlavourDetailModal from "../flavourDetailModal/FlavourDetailModal";

const Modal = ({
  modalClose,
  data,
  status,
  orderRejected,
  orderAccept,
  orderPickedUp,
  orderDelivered,
  orderUndo,
  assignRider,
  refresh,
  updatePage,
  city,
}) => {
  // console.log("🚀 ~ file: OrderDetailsModal.js:14 ~ Modal ~ data", data);
  const [collapse, setCollapse] = useState({
    mart: false,
    customer: false,
    rider: false,
    products: false,
  });
  const subTotal = () => {
    const price = data?.products?.map((item) => item.net);
    const subTotalPrice = price?.reduce((a, b) => a + b, 0);
    return subTotalPrice;
  };
  const [cancelModal, setCancelModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [removeModal, setRemoveModal] = useState(false);
  const [selectedRider, setSelectedRider] = useState();
  // const [notifiedStatus, setNotifiedStatus] = useState();
  const [restaurantModal, setRestaurantModal] = useState(false);
  const [flavourDetailModal, setFlavourDetailModal] = useState(false)
  const [flavours, setFlavours] = useState([])
  const dispatch = useDispatch();
  const assignedRider = async ({ data, rider }) => {
    if (data.status === "Admin Accepted") {
      refresh();
      try {
        const result = await axios.post(`${baseUrl}/orders/assignRider`, {
          orderId: data._id,
          riderName: rider.name,
          riderId: rider._id,
          status: "Rider Accepted",
          riderPhone: rider.phone,
          admin:true,
          orderNum: data.orderNum,
          actions: {
            name: "web",
          },
        });
        if (result.data.status == "200") {
          setAssignModal(false);
          updatePage();
        } else {
          setAssignModal(false);
          updatePage();
          alert(result.data.msg);
        }
      } catch (e) {
        console.log(e);
      }
    } else if (
      data.status === "Rider Accepted" ||
      data.status === "Rider Picked Up"
    ) {
      reassignRider({ data, rider });
    }
  };

  const reassignRider = async ({ data: order, rider }) => {
    refresh();
    try {
      const result = await axios.post(`${baseUrl}/admin/reAssignRider`, {
        orderId: order._id,
        riderName: rider.name,
        riderId: rider._id,
        riderPhone: rider.phone,
        actions: {
          name: "web",
        },
      });
      console.log("reassignRider", result.data);
      if (result.data.status == "200") {
        setAssignModal(false);
        updatePage();
        // alert(result.data.msg)
      } else {
        setAssignModal(false);
        refresh();
        updatePage();
        alert(result.data.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeRider = async () => {
    refresh();
    try {
      const result = await axios.post(`${baseUrl}/admin/removeRider`, {
        orderId: data._id,
        action: {
          name: "web",
        },
      });
      // console.log("saads", result.data);
      if (result.data.status == "200") {
        setRemoveModal(false);
        refresh();
        updatePage();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const editOrder = () => {
    setRestaurantModal(true);
    dispatch(
      addToBasket({
        product: data.products,
      }),
    );
    dispatch(productThunk(data.martId));
  };
  return (
    <div className={styles.modalContainer}>
      <div className={styles.header}>
        <h2>Order Details</h2>
        <IconButton onClick={modalClose}>
          <Close className={styles.close} />
        </IconButton>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.collapseContainer}>
          <div className={styles.collapseHeader}>
            <h3>Restaurant Information</h3>
            <IconButton
              onClick={() =>
                setCollapse({
                  ...collapse,
                  mart: !collapse.mart,
                })
              }
            >
              {!collapse.mart ? (
                <ExpandMore className={styles.expandIcon} />
              ) : (
                <ExpandLess />
              )}
            </IconButton>
          </div>
          {collapse.mart && (
            <div className={styles.collapseData}>
              <Person className={styles.collapseIcon} />
              <h5>{data?.martName}</h5>
              <LocalPhone className={styles.collapseIcon} />
              <h5>{data?.martPhone}</h5>
              <FmdGood className={styles.collapseIcon} />
              <h5>{data?.martAddress}</h5>
            </div>
          )}
        </div>
        <div className={styles.collapseContainer}>
          <div className={styles.collapseHeader}>
            <h3>Customer Information</h3>
            <IconButton
              onClick={() =>
                setCollapse({
                  ...collapse,
                  customer: !collapse.customer,
                })
              }
            >
              {!collapse.customer ? (
                <ExpandMore className={styles.expandIcon} />
              ) : (
                <ExpandLess />
              )}
            </IconButton>
          </div>
          {collapse.customer && (
            <div className={styles.collapseData}>
              <Person className={styles.collapseIcon} />
              <h5>{data?.name}</h5>
              <LocalPhone className={styles.collapseIcon} />
              <h5>{data?.phone}</h5>
              <FmdGood className={styles.collapseIcon} />
              <h5>{data?.address}</h5>
            </div>
          )}
        </div>
        <div className={styles.collapseContainer}>
          {data.riderName && (
            <div className={styles.collapseHeader}>
              <h3>Rider Information</h3>
              <IconButton
                onClick={() =>
                  setCollapse({
                    ...collapse,
                    rider: !collapse.rider,
                  })
                }
              >
                {!collapse.rider ? (
                  <ExpandMore className={styles.expandIcon} />
                ) : (
                  <ExpandLess className={styles.expandIcon} />
                )}
              </IconButton>
            </div>
          )}
          {collapse.rider && (
            <div className={styles.collapseData}>
              <Person className={styles.collapseIcon} />
              <h5>{data?.riderName}</h5>
              <LocalPhone className={styles.collapseIcon} />
              <h5>{data?.riderPhone}</h5>
            </div>
          )}
        </div>
        <div className={styles.collapseContainer}>
          <div className={styles.collapseHeader}>
            <h3>Products</h3>
            <IconButton
              onClick={() =>
                setCollapse({
                  ...collapse,
                  products: !collapse.products,
                })
              }
            >
              {!collapse.products ? (
                <ExpandMore className={styles.expandIcon} />
              ) : (
                <ExpandLess className={styles.expandIcon} />
              )}
            </IconButton>
          </div>
          {collapse?.products && (
            <div className={styles.productData}>
              <div className={styles.dataHeaderMain}>
                <div className={styles.dataHeader}>
                  <h5>Product Name</h5>
                  <h5>Description</h5>
                </div>

                <div className={styles.dataHeader1}>
                  <h5>Quantity</h5>
                  <h5>Unit Price</h5>
                  <h5>Total Price</h5>
                </div>
              </div>
              {data?.products?.map((item, index) => (
                <div key={index}

                  onClick={() => [setFlavourDetailModal(true),
                  setFlavours(item.selectedFlavours)]}
                  className={styles.productItemMain}>
                  <div

                    className={styles.productItem}>
                    <li> {item.productName}</li>
                    <h6>{item?.quantity}</h6>
                  </div>

                  <div className={styles.productItem1}>
                    <h6>{item?.count}</h6>
                    <h6>{item?.price}</h6>
                    <h6>{item?.net}</h6>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div style={{ padding: "0px 20px" }}>
          <div className={styles.orderTotal}>
            <h5>SubTotal</h5>
            <h5 style={{ color: "rgba(0,0,0,0.5)" }}>Rs : {subTotal()}</h5>
            <h5>Delivery Fee</h5>
            <h5 style={{ color: "rgba(0,0,0,0.5)" }}>
              Rs : {data?.deliveryCharges}
            </h5>
            <h5>Platform Fee</h5>
            <h5 style={{ color: "rgba(0,0,0,0.5)" }}>
              Rs : {data?.platformFee}
            </h5>
            <h5>Service Charges</h5>
            <h5 style={{ color: "rgba(0,0,0,0.5)" }}>
              Rs : {data?.serviceCharges}
            </h5>
            <h5>Discount</h5>
            <h5 style={{ color: "rgba(0,0,0,0.5)" }}>Rs : {data?.discount}</h5>

            <h5>Total</h5>
            <h5 style={{ color: "rgba(0,0,0,0.5)" }}>
              Rs : {data?.orderTotal}
            </h5>
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        {status.upcoming && (
          <div className={styles.buttonContainer}>
            <button
              onClick={() => orderAccept({ data, customerNotified: false ,status:'Admin Accepted'})}
              className={styles.bottomButton}
            >
              Accept
            </button>
            <button
              onClick={() => setCancelModal(true)}
              className={`${styles.bottomButton}
             ${styles.cancelButton}`}
            >
              Reject
            </button>
          </div>
        )}
        {status.accepted && data.orderType === "Delivery" ? (
          <div className={styles.buttonContainer}>
            <button
              disabled={data.status === "Admin Accepted"}
              onClick={() => orderPickedUp(data)}
              className={styles.bottomButton}
            >
              Pick Up
            </button>
            {data.paymentType === "COD" && (
              <button
                onClick={() => editOrder()}
                style={{
                  backgroundColor: "#f6f6f6",
                  border: "0.5px solid lightgrey",
                  color: "black",
                }}
                className={styles.bottomButton}
              >
                Edit Order
              </button>
            )}

            <button
              onClick={() => setAssignModal(true)}
              style={{
                backgroundColor: "#f6f6f6",
                border: "0.5px solid lightgrey",
                color: "black",
              }}
              className={styles.bottomButton}
            >
              {data.status === "Admin Accepted" ? "Assign" : "Reassign"}
            </button>

            {data.status === "Rider Accepted" && (
              <button
                onClick={() => setRemoveModal(true)}
                style={{
                  backgroundColor: "#f6f6f6",
                  border: "0.5px solid lightgrey",
                  color: "black",
                }}
                className={styles.bottomButton}
              >
                Remove Rider
              </button>
            )}

            <button
              onClick={() => setCancelModal(true)}
              className={`${styles.bottomButton}
              ${styles.cancelButton}`}
            >
              Cancel
            </button>
          </div>
        ) : status.accepted && (data.orderType === "PickUp" || "Pick Up") ? (
          <div className={styles.buttonContainer}>
            <button
              onClick={() =>
                orderAccept({
                  data,
                  customerNotified: true,
                  status: data.customerNotified
                    ? "Delivered"
                    : "Admin Accepted",
                })
              }
              className={styles.bottomButton}
            >
              {data.customerNotified
                ? "Deliver To Customer"
                : "Notify To Customer"}
            </button>
            {data.paymentType === 'COD' &&
              <button
                onClick={() => editOrder()}
                style={{
                  backgroundColor: "#f6f6f6",
                  border: "0.5px solid lightgrey",
                  color: "black",
                }}
                className={styles.bottomButton}
              >
                Edit Order
              </button>}

            <button
              onClick={() => setCancelModal(true)}
              className={`${styles.bottomButton}
                  ${styles.cancelButton}`}
            >
              Cancel
            </button>
          </div>
        ) : null}
        {status.picked && (
          <div className={styles.buttonContainer}>
            <button
              onClick={() => orderDelivered(data)}
              className={styles.bottomButton}
            >
              Delivered
            </button>
            {data.paymentType === 'COD' &&
              <button
                onClick={() => editOrder()}
                style={{
                  backgroundColor: "#f6f6f6",
                  border: "0.5px solid lightgrey",
                  color: "black",
                }}
                className={styles.bottomButton}
              >
                Edit Order
              </button>}
            <button
              onClick={() => setAssignModal(true)}
              style={{
                backgroundColor: "#f6f6f6",
                border: "0.5px solid lightgrey",
                color: "black",
              }}
              className={styles.bottomButton}
            >
              {data.status == "Admin Accepted" ? "Assign" : "Reassign"}
            </button>
            <button
              onClick={() => setRemoveModal(true)}
              style={{
                backgroundColor: "#f6f6f6",
                border: "0.5px solid lightgrey",
                color: "black",
              }}
              className={styles.bottomButton}
            >
              Remove Rider
            </button>

            <button
              onClick={() => setCancelModal(true)}
              className={`${styles.bottomButton}
              ${styles.cancelButton}`}
            >
              Cancel
            </button>
          </div>
        )}
        {status.rejected && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            className={`${styles.buttonContainer}`}
          >
            <button
              onClick={() => orderUndo(data)}
              className={styles.bottomButton}
            >
              Reopen
            </button>
          </div>
        )}
      </div>
      {cancelModal && (
        <div className={styles.cancelModal}>
          <CancelModal
            modalClose={() => [setCancelModal(false), setCancelReason("")]}
            setReason={(t) => setCancelReason(t)}
            reason={cancelReason}
            cancelOrder={() =>
              orderRejected({ data: data, reason: cancelReason })
            }
          />
        </div>
      )}
      {assignModal && (
        <div className={styles.cancelModal}>
          <AssignModal
            closeModal={() => setAssignModal(false)}
            assignRider={(rider) => [
              assignedRider({ rider, data }),
              setSelectedRider(rider),
            ]}
            city={city}
          />
        </div>
      )}
      {removeModal && (
        <div className={styles.cancelModal}>
          <RemoveRider
            modalClose={() => setRemoveModal(false)}
            toRemoveRider={() => removeRider()}
          />
        </div>
      )}
      {restaurantModal && (
        <div className={styles.cancelModal}>
          <RestaurantModal
            restaurantModalClose={() => [setRestaurantModal(false), dispatch(clearProducts())]}
            restaurantData={data}
            refresh={refresh}
          />
        </div>
      )}
      {
        (flavourDetailModal && flavours?.length > 0) &&
        <div
          className={styles.cancelModal}>
          <FlavourDetailModal
            flavourModalClose={() => setFlavourDetailModal(false)}
            flavours={flavours}
          />
        </div>
      }
    </div>
  );
};

export default Modal;
