import React from "react";
import { Menu, Lock, AccountBalance, Home, Group, Person } from "@mui/icons-material";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link, useNavigate } from "react-router-dom";
import "./SideBar.css";
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { useDispatch } from "react-redux";
import { removeData } from "../../features/dashboardSlice";
function SideBar({ sidebarGo }) {
  const dispatch = useDispatch()
  const userData = JSON.parse(localStorage.getItem('userLogin'))
  console.log("🚀 ~ file: SideBar.js:10 ~ SideBar ~ userData:", userData)
  const navigate = useNavigate()
  const signOut = () => {
    localStorage.removeItem('userLogin')
    localStorage.removeItem('currentCity')
    dispatch(removeData(
      {
        data: {
          ordersData: null,
          status: null,
          totalRestaurant: null,
          usersData: null,
          weeklyAvgOrderAmount: null,
          weeklyOrderProfit: null,
          networkError: null,
        },
      }
    ))
    navigate('/login')
  }
  return (
    <div className={sidebarGo ? sidebarGo : "sidebar"}>
      <div className="sidebar__container">

        <Menu sx={{ fontSize: "30px", color: "white", cursor: "pointer" }} />
        <Link to="/main" className="link">
          <Home
            className="icon"
            sx={{ fontSize: "30px", color: "white", cursor: "pointer" }}
          />
          <h5 className="text">Home</h5>
        </Link>
        <Link to="/tracking" className="link">
          <ShareLocationIcon
            sx={{ color: "white", fontSize: "30px", cursor: "pointer" }}
          />
          <h5 className="text" >Tracking</h5>
        </Link>
        {userData?.adminType === 'super admin' && <Link to="/record" className="link" style={{ margin: 0, padding: 0 }}>
          <AccountBalance
            sx={{ fontSize: "30px", color: "white", cursor: "pointer" }}
          />
          <h5 className="text">Record</h5>
        </Link>}
        {userData?.adminType === 'super admin' && <Link
          to="/customers"
          className="link" style={{ margin: 0, padding: 0, }}
        >
          <Group
            sx={{ fontSize: "30px", color: "white", cursor: "pointer" }}
          />
          <h5 className="text" >Customer</h5>
        </Link>
        }
        {userData?.adminType === 'super admin' && <Link to='/selectCity' className="link">
          <LocationOnIcon sx={{ fontSize: "30px", color: "white", cursor: "pointer" }} />
          <h5 className="text" style={{ textAlign: 'center' }}>Cities</h5>
        </Link>}
        <div
          onClick={signOut}
          className="link">
          <Lock sx={{ fontSize: "30px", color: "white", cursor: "pointer" }} />
          <h5 className="text">Logout</h5>
        </div>

      </div>
    </div>
  );
}

export default SideBar;
