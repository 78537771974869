import MainScreen from "./pages/MainScreen/MainScreen";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Link,
} from "react-router-dom";
import Orders from "./pages/orders/Orders";
import { Provider, useSelector } from "react-redux";
import { store } from "./store";
import Records from "./pages/records/Records";
import OrderTracking from "./pages/orderTracking/OrderTracking";
import Login from "./pages/login/Login";
import { selectUser } from "./features/userSlice";
import { useEffect } from "react";
import Loading from "./components/loading/Loading";
import Splash from "./pages/splash/Splash";
import Customers from "./pages/customers/Customers";
import SelectCity from "./pages/selectCity/SelectCity";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/login" element={<Login docTitle="Login" />} />
          <Route path="/selectCity" element={<SelectCity />} />
          <Route
            exact
            path="/main"
            element={<MainScreen docTitle="Dastak Dashboard" />}
          />
          <Route
            path="/record"
            element={<Records docTitle="Record | Dastak" />}
          />
          <Route
            path="/main/order/:id"
            element={<Orders docTitle="Orders | Dastak" />}
          />
          <Route
            path="/tracking"
            element={<OrderTracking docTitle="Live Tracking | Dastak" />}
          />
          <Route
            path="/customers"
            element={<Customers docTitle="Live Tracking | Dastak" />}
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
