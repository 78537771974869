import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import Home from "../home/Home";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Orders, selectData, setData } from "../../features/dashboardSlice";
import styles from "./MainScreen.module.css";
import { useNavigate } from "react-router-dom";
import OneSignal from "react-onesignal";
import Loading from "../../components/loading/Loading";
function MainScreen({ docTitle }) {
  const dispatch = useDispatch();
  const { ordersData } = useSelector(selectData);
  const [barDisplay, setBarDisplay] = useState(true);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userLogin"));
  const selectedCity = JSON.parse(localStorage.getItem("currentCity"));
  useEffect(() => {
    OneSignal.init({ appId: "54075831-8219-4882-ab1e-45062acc2aa1" });
  }, []);
  useEffect(() => {
    if (userData) {
      document.title = docTitle;
      if (ordersData === null) {
        dispatch(Orders(selectedCity.city)); //calling createAsyncThunk
      }

    } else {
      navigate("/login");
    }
  }, []);

  if (!userData) {
    return null;
  }
  // const baseUrl = BASEURL.REACT_APP_BASE_URL;
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const result = await axios.get(`${baseUrl}/web/v1/dashboard`);
  //       if (result.data.status == "200") {
  //         dispatch(setData({ data: result.data }));
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   })();

  // }, []);

  return (
    <div className={styles.MainScreen}>
      <div className={styles.MainScreen__sideBar}>
        <SideBar sidebarGo={barDisplay ? "sidebar" : "sidebar1"} />
      </div>
      <div className={styles.MainScreen__home}>
        <Header clickOnImage={() => setBarDisplay(!barDisplay)} />
        <Home />
      </div>
    </div>
  );
}

export default MainScreen;
