import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REACT_APP_BASE_URL as baseUrl } from "../config/keys";
import axios from "axios";
const initialState = {
  products: [],
};

export const productThunk = createAsyncThunk("product", async (martId) => {
  try {
    const result = await axios.post(`${baseUrl}/products/allProducts`, {
      martId: martId,
    });
    if (result.data.status == "200") {
      return result.data;
    
    }
  } catch (e) {
    console.log(e);
  }
});

export const productSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    clearProducts: (state, action) => {
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(productThunk.fulfilled, (state, action) => {
      state.products = action.payload;
    });
  },
});

export const { clearProducts } = productSlice.actions;
export const selectProduct = (state) => state.productsReducer;

export default productSlice.reducer;
