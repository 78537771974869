import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const SelectBox = ({ data, label, selectItem, disable,defaultValue }) => {
  console.log("🚀 ~ file: SelectBox.js:5 ~ SelectBox ~ data:", data)
  return (
    <FormControl disabled={disable} fullWidth style={{ margin: "10px" }}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        defaultValue={defaultValue}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Age"
        onChange={(e) => selectItem(e.target.value)}
      >
        {data?.map((item, index) => (
          <MenuItem key={index}
          value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectBox;
