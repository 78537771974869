import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import styles from "./Splash.module.css";
const Splash = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userLogin"));
    const selectedCity = JSON.parse(localStorage.getItem("currentCity"));
    setTimeout(() => {
      if (data && selectedCity) {
        navigate("/main");
      } else if (data && !selectedCity) {
        navigate("/selectCity");
      } else {
        navigate("/login");
      }
    }, 1000);
  }, []);
  return (
    <div className={styles.splash}>
      <img src={require("../../assets/dastak.png")} alt="" />
      <BarLoader
        color="#ff7378"
        loading={true}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Splash;
