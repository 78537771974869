import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/sidebar/SideBar";
import TrackingCard from "../../components/TrackingCard/TrackingCard";
import styles from "./OrderTracking.module.css";
const OrderTracking = ({ docTitle }) => {
  const userData = JSON.parse(localStorage.getItem("userLogin"));
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      document.title = docTitle;
    } else {
      navigate("/login");
    }
  }, []);
  if (!userData) {
    return null;
  }
  return (
    <div className={styles.orderTracking}>
      <div className={styles.sidebar}>
        <SideBar />
      </div>
      <div className={styles.trackingCard}>
        <TrackingCard />
      </div>
    </div>
  );
};

export default OrderTracking;
