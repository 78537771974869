import React from "react";
import styles from "./RemoveRider.module.css";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
const RemoveRider = ({ modalClose,toRemoveRider }) => {
    return <div className={styles.removeRider}>
        <div className={styles.header}>
            <IconButton
                onClick={() => modalClose()}
            >
                <CloseIcon />
            </IconButton>
        </div>
        <div className={styles.removeContentContainer}>
            <h5>
                Are you sure you want to remove rider?
            </h5>
            <button
                onClick={() => toRemoveRider()}
            >
                Confirm
            </button>
        </div>
    </div>;
};

export default RemoveRider;
