import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import { Person, Lock, Visibility, VisibilityOff } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/userSlice";
import { BounceLoader, ClipLoader, RingLoader } from "react-spinners";
import { IconButton } from "@mui/material";
const Login = ({ docTitle }) => {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = docTitle;
  }, []);
  const signIn = async (e) => {
    e.preventDefault();
    try {
      if (number && password) {
        setLoading(true);
        const res = await axios.post(`${baseUrl}/admin/signIn`, {
          phone: number,
          password: password,
        });
        if (res.data.status == "200") {
          console.log('res',res.data)
          dispatch(setUser({ user: res.data }));
          localStorage.setItem(
            "userLogin",
            JSON.stringify({
              email: res.data.data.email,
              name: res.data.data.name,
              adminType: res.data.data.adminType,
              adminCities: res.data.data.adminCities,
              city:res.data.data.city,
              status: true,
            })
          );
          navigate("/selectCity");
          setLoading(false);
        } else {
          setLoading(false);
          alert(`${res.data.msg}\n Enter Correct Password and Number`);
        }
      } else {
        alert("Fields are Empty");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const passwordVisible = () => {
    setShowPassword(!showPassword);
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "grid",
          placeContent: "center",
        }}
      >
        <ClipLoader color="#ff7378" size={40} />
      </div>
    );
  }

  return (
    <div className={styles.login}>
      <div className={styles.login__container}>
        <div className={styles.login__circles}>
          <div className={styles.login__circleContainer}>
            <div className={styles.circle__1} />
            <div className={styles.circle__2} />
          </div>
          <div className={styles.circle__curve} />
        </div>

        <div className={styles.login__authContainer}>
          <img
            src={require("../../assets/dastak.png")}
            alt=""
            className={styles.image}
          />

          <h3>Login To Continue</h3>
          <form action="">
            <div className={styles.input}>
              <input
                type="number"
                placeholder="Enter Number"
                value={number}
                onChange={(text) => setNumber(text.target.value)}
              />
              <div className={styles.icon}>
                <Person />
              </div>
            </div>
            <div className={styles.input}>
              <input
                onChange={(text) => setPassword(text.target.value)}
                type={passwordType}
                name=""
                id=""
                placeholder="Enter Password"
              />
              <div className={styles.eyeButton}>
                <IconButton
                  // className={styles.eyeButton}
                  onClick={passwordVisible}
                >
                  {!showPassword ? (
                    <Visibility sx={{ color: "black", fontSize: "20px" }} />
                  ) : (
                    <VisibilityOff sx={{ color: "black", fontSize: "20px" }} />
                  )}
                </IconButton>
              </div>

              <div className={styles.icon}>
                <Lock />
              </div>
            </div>
          </form>

          <button type="submit" onClick={signIn} className={styles.LoginButton}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
