import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Card.module.css";

const Card = ({
  totalCount,
  Title,
  icon,
  id,
  navigateOrder,
  ordersType
}) => {
  const navigate = useNavigate()
  return (
    <div className={styles.card}
      onClick={() => id == '1' ? navigate(`order/${id}`) : ''}
    >

      <div className={styles.card__title}>
        <h5>{Title}</h5>
        {icon}
      </div>
      <div className={styles.card__detail}>
        <h3>{totalCount}</h3>
      </div>
    </div>
  );
};

export default Card;
