import React, { useEffect, useState } from "react";
import styles from "./OrdersCardStyle.module.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useDispatch, useSelector } from "react-redux";
import { Orders, selectData } from "../../features/dashboardSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
const OrdersCard = ({ title, id }) => {
  const reduxData = useSelector(selectData);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (reduxData.ordersData == null) {
      dispatch(Orders());
    }
  }, []);
  useEffect(() => {
    setRecords();
  }, [page, recordPerPage, reduxData]);

  const setRecords = async () => {
    var ordersData = [];
    id === "1"
      ? await (ordersData = reduxData?.weeklyOrders)
      : id === "2"
        ? await (ordersData = reduxData?.monthlyOrders)
        : await (ordersData = reduxData?.yearlyOrders);

    const sortingOrderData = ordersData && [...ordersData];
    var sortedRecord = sortingOrderData?.sort(function (a, b) {
      // const reg = /am|pm/;
      // const aTime = a.time.replace(reg, "").trimEnd();
      // const bTime = b.time.replace(reg, "").trimEnd();

      const aTime = moment(a.time, ["h:mm A"]).format("HH:mm");
     
      const bTime = moment(b.time, ["h:mm A"]).format("HH:mm");
   
      const aDate = a.date.split("-").reverse().join("-");
     
      const bDate = b.date.split("-").reverse().join("-");
     

      return new Date(bDate + " " + bTime) - new Date(aDate + " " + aTime);
    });


    const totalPages = Math.ceil(ordersData?.length / recordPerPage);
    setTotalPage(totalPages);
    const indexOfLastRecord = page * recordPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
    const record = sortedRecord?.slice(indexOfFirstRecord, indexOfLastRecord);
    setCurrentRecords(record);
  };

  const pageNext = () => {
    if (page !== totalPage) {
      setPage(page + 1);
    }
  };
  const pageBack = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  return (
    <div className={styles.orders__card}>
      <div className={styles.orders__cardHeading}>
        <h3>{title} Orders</h3>
      </div>

      <div className={styles.orders__cardTitleBar}>
        <h5 id={styles.orderid} className={styles.cardTitleBar__text}>
          Orders ID
        </h5>

        <h5
          className={styles.cardTitleBar__text}
          style={{ position: "relative", left: "20px" }}
        >
          Date-Time
        </h5>
        <h5 className={styles.cardTitleBar__text}>Order Type</h5>
        <h5
          className={styles.cardTitleBar__text}
          style={{ position: "relative", left: "10px" }}
        >
          Price
        </h5>

        <h5 className={styles.cardTitleBar__text}>Order Status</h5>

        <h5
          className={styles.cardTitleBar__text}
          style={{ position: "relative", right: "10px" }}
        >
          View Details
        </h5>
      </div>
      <div className={styles.orders__cardData}>
        {currentRecords ? (
          currentRecords?.map((item, index) => (
            <div key={item._id} className={styles.orders__cardDataItem}>
              <h6
                id={styles.orderid1}
                className={styles.orders__cardDataItemText}
              >
                {item._id}
              </h6>
              <h6 className={styles.orders__cardDataItemText}>
                {item.date}
                <br />
                {item.time}
              </h6>
              <h6 className={styles.orders__cardDataItemText}>
                {item.orderType}
              </h6>
              <h6 className={styles.orders__cardDataItemText}>
                {item.orderTotal}
              </h6>

              <h6
                className={styles.orders__cardDataItemText}
                style={{ color: "mediumseagreen" }}
              >
                {item.status}
              </h6>
              <h6
                className={styles.orders__cardDataItemText}
                style={{ color: "red", cursor: "pointer", fontSize: "16px" }}
              >
                View Details
              </h6>
            </div>
          ))
        ) : (
          <div className={styles.orders__cardDataItem}>
            <Skeleton width={60} height={20} />
            <div className={styles.orders__cardDataItemText}>
              <Skeleton width={60} height={20} />
              <br />
              {/* <Skeleton width={60} height={20} /> */}
            </div>
            <Skeleton width={60} height={20} />
            <Skeleton width={60} height={20} />
            <Skeleton width={60} height={20} />
            {/* <Skeleton width={60} height={20} /> */}
          </div>
        )}
        <div className={styles.itemSeparator} />
      </div>
      <div className={styles.order__nextOrder}>
        <div className={styles.rowSelect} style={{ marginRight: "20px" }}>
          <select
            onChange={(e) => setRecordPerPage(e.target.value)}
            id={styles.row}
            name="row"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
        </div>
        <div
          onClick={() => pageBack()}
          className={styles.order__iconBox}
          style={{ marginRight: "5px" }}
        >
          <NavigateBeforeIcon />
        </div>
        <div className={styles.orders_nextOrderStatus}>
          <span>{page}</span>
        </div>
        <div
          onClick={() => pageNext()}
          className={styles.order__iconBox}
          style={{ marginLeft: "5px" }}
        >
          <NavigateNextIcon />
        </div>
      </div>
    </div>
  );
};

export default OrdersCard;
