import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RecordCard from "../../components/recordCard/RecordCard";
import SideBar from "../../components/sidebar/SideBar";
import styles from "./Records.module.css";
const Records = ({docTitle}) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userLogin"));
  useEffect(() => {
    if (user) {
      document.title = docTitle;
    } else {
      navigate("/login");
    }
  }, []);
  if (!user) {
    return null;
  }
  return (
    <div className={styles.weekly__container}>
      <div className={styles.weekly__sideBar}>
        <SideBar />
      </div>
      <div className={styles.weeklyExpense__card}>
        <RecordCard />
      </div>
    </div>
  );
};

export default Records;
