import React, { useEffect, useState } from "react";
import styles from "./AddToCart.module.css";
import { Close, Remove, Add } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Flavour from "../flavours/Flavour";
import { useDispatch } from "react-redux";
import {
  addProductIntoCartForFlavour,
  addProductToCart,
} from "../../features/basketSlice";
const AddToCart = ({ modalClose, product }) => {
  // console.log("🚀 ~ file: AddToCart.js:6 ~ AddToCart ~ product", product);
  const [count, setCount] = useState(1);
  const [currentPrice, setCurrentPrice] = useState(product.price);
  const [selectedFlavour, setSelectedFlavour] = useState([]);
  const dispatch = useDispatch();
  const addProduct = () => {
    setCount(count + 1);
  };
  const removeProduct = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  useEffect(() => {
    if (product.discount <= "0") {
      setCurrentPrice(count * product.price);
    } else {
      setCurrentPrice(count * product.discountedPrice);
    }
  }, [count]);
  const addProductIntoCart = (item) => {
    if (item?.actualPrice && selectedFlavour.length <= 0) {
      dispatch(
        addProductToCart({
          productName: item.productName,
          quantity: item.quantity,
          price: item.discount <= "0" ? item.price : item.discountedPrice,
          type: item.type,
          count: count,
          net: currentPrice,
          actualPrice: item.actualPrice,
        })
      );
    } else if (item?.actualPrice && selectedFlavour.length > 0) {
      dispatch(
        addProductToCart({
          productName: item.productName,
          quantity: item.quantity,
          price: item.discount <= "0" ? item.price : item.discountedPrice,
          type: item.type,
          count: count,
          net: currentPrice,
          selectedFlavours: selectedFlavour,
          actualPrice: item.actualPrice,
        })
      );
    } else if (!item.actualPrice && selectedFlavour.length > 0) {
      dispatch(
        addProductToCart({
          productName: item.productName,
          quantity: item.quantity,
          price: item.discount <= "0" ? item.price : item.discountedPrice,
          type: item.type,
          count: count,
          net: currentPrice,
          selectedFlavours: selectedFlavour,
        })
      );
    } else {
      dispatch(
        addProductToCart({
          productName: item.productName,
          quantity: item.quantity,
          price: item.discount <= "0" ? item.price : item.discountedPrice,
          type: item.type,
          count: count,
          net: currentPrice,
        })
      );
    }

    modalClose();
  };

  const findFlavour = (flavor) => {
    const isFlavour = selectedFlavour?.some(
      (item) => item.title === flavor.title
    );
    if (isFlavour) {
      const checked = selectedFlavour?.map((item) => {
        return item.title === flavor.title
          ? { ...item, option: flavor.option }
          : item;
      });
      setSelectedFlavour(checked);
    } else {
      setSelectedFlavour([...selectedFlavour, flavor]);
    }
  };
  console.log("selected", selectedFlavour);
  return (
    <div className={styles.addToCart}>
      <div className={styles.header}>
        <IconButton onClick={modalClose}>
          <Close />
        </IconButton>
      </div>
      <div className={styles.subHeader}>
        <div className={styles.title}>
          <h5>{product.productName}</h5>
          <h6>{product.quantity}</h6>
        </div>
        <div className={styles.price}>
          <h5>from</h5>
          <h5
            style={{
              textDecorationLine:
                product.discount > "0" ? "line-through" : "none",
            }}
          >
            {" "}
            Rs.{product.price}
          </h5>
          {product.discount > "0" && (
            <h5 style={{ color: "#ff7378" }}>Rs. {product.discountedPrice}</h5>
          )}
        </div>
      </div>
      <div className={styles.contentContainer}>
        {product?.specifications?.length >= 1 && (
          <div className={styles.flavour}>
            <Flavour
              specification={product?.specifications}
              findFlavour={(item) => findFlavour(item)}
            />
          </div>
        )}

        <div className={styles.addToCartIconButtonContainer}>
          <div className={styles.content}>
            <IconButton onClick={() => removeProduct()}>
              <Remove
                disable={count == "1"}
                sx={{
                  color: count == "1" ? "lightgrey" : "#ff7378,",
                  fontSize: "25px",
                }}
              />
            </IconButton>
            <span className={styles.number}>{count}</span>
            <IconButton onClick={() => addProduct()}>
              <Add
                sx={{
                  color: "#ff7378",
                  fontSize: "25px",
                }}
              />
            </IconButton>
          </div>
          <button
            disabled={selectedFlavour.length < product?.specifications?.length}
            onClick={() => addProductIntoCart(product)}
            className={styles.addToCartButton}
          >
            Add To Cart Rs.{currentPrice}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
