import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../config/keys";
const initialState = {
  orderDetails: {},
};
// export const orderThunk = createAsyncThunk("order", async (item) => {
//   const result = await axios.post(`${baseUrl}/admin/allOrders`, {
//     city: item.city,
//     adminType: "",
//     zone: item.zone,
//   });
//   if (result.data.status == "200") {
//     return result.data;
//   }
// });
export const orderSlice = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    addOrder: (state, action) => {
      state.orderDetails = action.payload;
    },
  },
  //   extraReducers: (builder) => {
  //     builder.addCase(orderThunk.fulfilled, (state, action) => {
  //       state.item = action.payload;
  //     });
  //   },
});

export const { addOrder } = orderSlice.actions;
export const selectOrder = (state) => state.orderReducer;
export default orderSlice.reducer;
