import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refresh: false,
};

export const refreshSlice = createSlice({
  name: "refresh",
  initialState: initialState,
  reducers: {
    setRefreshPage: (state, action) => {
      state.refresh = action.payload.value;
    },
  },
});

export const { setRefreshPage } = refreshSlice.actions;
export const selectRefresh = (state) => state.refreshReducer.refresh;

export default refreshSlice.reducer;
