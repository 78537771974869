import { IconButton } from "@mui/material";
import { Close, Remove, Add, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import styles from "./Cart.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToCart,
  clearTheBasket,
  completeRemoveProductFromBasket,
  removeProductFromBasket,
  selectedBasketItem,
} from "../../features/basketSlice";
import { selectProduct } from "../../features/productSlice";
import { selectOrder } from "../../features/orderSlice";
import axios from "axios";
import { REACT_APP_BASE_URL as baseUrl } from "../../config/keys";
import { selectRefresh, setRefreshPage } from "../../features/refreshSlice";
import { ClipLoader } from "react-spinners";

const Cart = ({ cartClose, product }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const basketProducts = useSelector(selectedBasketItem);
  // console.log("🚀 ~ file: Cart.js:15 ~ Cart ~ items:", basketProducts);
  const { products } = useSelector(selectProduct);
  const { orderDetails } = useSelector(selectOrder);
  console.log("🚀 ~ file: Cart.js:28 ~ Cart ~ orderDetails:", orderDetails)
  const refreshPage = useSelector(selectRefresh);

  const subTotal = () => {
    const result = basketProducts?.product.reduce((a, b) => a + b.net, 0);
    return result;
  };
  const totalPrice = () => {
    if (orderDetails.orderType === "Delivery") {
      const total =
        subTotal() +
        Number(orderDetails?.deliveryCharges) +
        Number(orderDetails?.platformFee) +
        Number(orderDetails?.serviceCharges) +
        Number(orderDetails?.discount);
      return total;
    } else if (orderDetails.orderType === "PickUp") {
      return subTotal();
    }
  };
  const addProductIntoBasket = (item) => {
    dispatch(
      addProductToCart({
        productName: item.productName,
        quantity: item.quantity,
        price: item.price,
        type: item.type,
        count: 1,
        net: item.price,
        selectedFlavours: item.selectedFlavours,
      })
    );
  };
  const updateOrder = async () => {
    setLoading(true);
    try {
      const result = await axios.post(`${baseUrl}/admin/updateOrder`, {
        orderId: orderDetails._id,
        products: basketProducts.product,
        orderTotal: totalPrice(),
        actions: { name: "web" },
      });
      if (result.data.status == "200") {
        dispatch(setRefreshPage({ value: !refreshPage }));
        alert(result.data.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className={styles.cart}>
      <div className={styles.header}>
        <h5>Cart</h5>
        <IconButton onClick={cartClose}>
          <Close />
        </IconButton>
      </div>
      {basketProducts?.product?.length === 0 ? (
        <div className={styles.titleContainer}>
          <h5>Your cart</h5>
          <h6>Start Adding Item to Cart</h6>
        </div>
      ) : (
        <div className={styles.productContainer}>
          {basketProducts?.product?.map((item) => (
            <div className={styles.product}>
              <h5>{item.productName}</h5>
              <div className={styles.productPrice}>
                <h6>Rs. {item.net}</h6>

                <div className={styles.counter}>
                  {item.count > 1 ? (
                    <IconButton
                      disabled={item.count == 1}
                      onClick={() =>
                        dispatch(
                          removeProductFromBasket({
                            productName: item.productName,
                            quantity: item.quantity,
                            productName: item.productName,
                            quantity: item.quantity,
                            price: item.price,
                            type: item.type,
                            count: 1,
                            net: item.price,
                            selectedFlavours: item.selectedFlavours,
                          })
                        )
                      }
                      className={styles.icon}
                    >
                      <Remove sx={{ color: "#ff7378" }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={styles.icon}
                      onClick={() =>
                        dispatch(
                          completeRemoveProductFromBasket({
                            productName: item.productName,
                            quantity: item.quantity,
                            productName: item.productName,
                            quantity: item.quantity,
                            price: item.price,
                            type: item.type,
                            count: 1,
                            net: item.price,
                            selectedFlavours: item.selectedFlavours,
                          })
                        )
                      }
                    >
                      <Delete sx={{ color: "#ff7378" }} />
                    </IconButton>
                  )}

                  <span>{item.count}</span>
                  <IconButton onClick={() => addProductIntoBasket(item)}>
                    <Add sx={{ color: "#ff7378" }} />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {loading && (
        <div className={styles.loading}>
          <ClipLoader color="#ff7378" size={50} />
        </div>
      )}

      <div style={{ padding: "0px 15px" }}>
        {basketProducts.product.length >= 1 && (
          <div className={styles.totalPriceContainer}>
            <h5>Subtotal</h5>
            <span>Rs. {subTotal()}</span>
            {orderDetails.orderType === "Delivery" && (
              <>
                <h5>Delivery Fee</h5>
                <span>Rs. {orderDetails?.deliveryCharges}</span>
                <h5>Platform Fee</h5>
                <span>Rs. {orderDetails?.platformFee}</span>
                <h5>Service Charges</h5>
                <span>Rs. {orderDetails?.serviceCharges}</span>
                <h5>Discount</h5>
                <span>Rs. {orderDetails?.discount}</span>
              </>
            )}
            <h5>Total</h5>
            <span style={{ fontWeight: 600 }}>Rs. {totalPrice()}</span>
          </div>
        )}
      </div>
      <div className={styles.updateButton}>
        <button
          onClick={() => updateOrder()}
          style={{
            backgroundColor:
              basketProducts.product.length === 0 || loading === true
                ? "#cacaca"
                : "#ff7378",
          }}
          disabled={basketProducts?.product?.length === 0 || loading === true}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default Cart;
