import React from "react";
import styles from "./Box.module.css";
import IconButton from "@mui/material/IconButton";
import { MoreHoriz } from "@mui/icons-material";
const Box = ({ title, number }) => {
  return (
    <div className={styles.box}>
      <div className={styles.title}>
        <h5>{title}</h5>
      </div>
      <div className={styles.number}>
        <h3>{number}</h3>
      </div>
      <div className={styles.MoreHorizIcon}>
        <IconButton sx={{ marginBottom: "10px" }}>
          <MoreHoriz sx={{ color: "#000" }} />
        </IconButton>
      </div>
    </div>
  );
};

export default Box;
