import React, { useState, useEffect } from "react";
import { Visibility } from "@mui/icons-material";
import { BarChart } from "@mui/icons-material";
import { CompareArrows } from "@mui/icons-material";
import styles from "./Home.module.css";
import Box from "../../components/box/Box";
import Graph from "../../components/graph/Graph";
import { Person } from "@mui/icons-material";
import { DirectionsBike } from "@mui/icons-material";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Card from "../../components/card/Card";
import Loading from "../../components/loading/Loading";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectData, setData } from "../../features/dashboardSlice";
import { selectUser } from "../../features/userSlice";

const Home = () => {
  const {
    ordersData,
    weeklyAvgOrderAmount,
    weeklyOrderProfit,
    totalRestaurants,
    usersData,
  } = useSelector(selectData);

  if (ordersData == null)
    return (
      <div className={styles.home__loading}>
        <Loading />
      </div>
    );
  return (
    <div className={styles.home}>
      <div className={styles.home__orderbox}>
        {ordersData?.map((items, index) => {
          return (
            <Card
              key={items?.id}
              totalCount={items?.totalOrders?.toLocaleString()}
              Title={items?.typeTitle}
              id={items?.id}
              icon={
                items.ordersType === "week" ? (
                  <BarChart sx={{ fontSize: "25px" }} />
                ) : items.ordersType === "month" ? (
                  <Visibility sx={{ fontSize: "25px" }} />
                ) : (
                  <CompareArrows sx={{ fontSize: "25px" }} />
                )
              }
              plusIcon={
                <AiOutlinePlusCircle size={18} style={{ marginTop: "1px" }} />
              }
            />
          );
        })}
      </div>
      <div className={styles.container}>
        <div className={styles.box__avg}>
          <Box
            title="Weekly Avg Order Amount"
            number={`Rs.${
              weeklyAvgOrderAmount !== null
                ? weeklyAvgOrderAmount?.toFixed()
                : ""
            }`}
          />
          <Box title="No of Restaurants" number={totalRestaurants} />
        </div>
        <div className={styles.graph__container}>
          <Graph data={weeklyOrderProfit} />
        </div>
      </div>
      <div className={styles.home__orderbox}>
        {usersData?.map((item, index) => {
          return (
            <Card
              key={item.id}
              Title={item.title}
              totalCount={item?.number?.toLocaleString()}
              icon={
                item.type === "customer" ? (
                  <Person sx={{ fontSize: "25px" }} />
                ) : (
                  <DirectionsBike sx={{ fontSize: "25px" }} />
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default Home;
