import React from "react";
import styles from "./Graph.module.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import IconButton from "@mui/material/IconButton";
import { MoreHoriz } from "@mui/icons-material";
// const data = [
//     {
//         day: "Monday",
//         profit: 2400,

//     },
//     {
//         day: "Tuesday",
//         profit: 1398,

//     },
//     {
//         day: "Wednesday",
//         profit: 9800,

//     },
//     {
//         day: "Thursday",
//         profit: 3908,

//     },
//     {
//         day: "Friday",
//         profit: 4800,

//     },
//     {
//         day: "Saturday",
//         profit: 3800,

//     },
//     {
//         day: "Sunday",
//         profit: 4300,

//     },
// ];

const Graph = ({ data }) => {
  return (
    <div className={styles.graph__box}>
      <div className={styles.graph__title}>
        <p className={styles.graph_titlespan}> Profit this week</p>
        <IconButton>
          <MoreHoriz sx={{ fontSize: "30px", color: "#000" }} />
        </IconButton>
      </div>
      <ResponsiveContainer
        width="95%"
        height="80%"
        className={styles.responsive__box}
      >
        <LineChart
          width={500}
          height={300}
          data={data}
          className={styles.graph__lineChart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="ourProfit"
            stroke="#702963"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Graph;
